import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { StripScript, IsUrl, IsFunc, IsObj, IsEmail, IsPhone, InArr, HasValue } from '@mini-code/base-func/filter';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "filter--过滤器"
    }}>{`Filter / 过滤器`}</h1>
    <p>{`basic-helper 提供了一些常用的数据过滤器`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  StripScript, IsUrl, IsFunc, IsObj, IsEmail, IsPhone, InArr, HasValue
} from 'basic-helper/filter';
`}</code></pre>
    <h2 {...{
      "id": "过滤-script-标签"
    }}>{`过滤 script 标签`}</h2>
    <Playground __position={0} __code={'() => {\n  const scriptStr =\n    \'<span>其他内容</span><script>alert(\"script 标签\")</script>\'\n  const [content, setContent] = useState(scriptStr)\n  return (\n    <div>\n      <div>内容: {content}</div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          console.log(StripScript(content))\n          setContent(StripScript(content))\n        }}\n      >\n        过滤 script\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      StripScript,
      IsUrl,
      IsFunc,
      IsObj,
      IsEmail,
      IsPhone,
      InArr,
      HasValue
    }} mdxType="Playground">
  {() => {
        const scriptStr = '<span>其他内容</span><script>alert("script 标签")</script>';
        const [content, setContent] = useState(scriptStr);
        return <div>
          <div>内容: {content}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            console.log(StripScript(content));
            setContent(StripScript(content));
          }}>过滤 script</span>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "isurl"
    }}>{`IsUrl`}</h2>
    <Playground __position={1} __code={'() => {\n  const url = \'https://www.google.com\'\n  const [isUrl, setCondition] = useState(IsUrl(url))\n  return (\n    <div>\n      <div>是否 url: {isUrl + \'\'}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        defaultValue={url}\n        onChange={e => {\n          const val = e.target.value\n          setCondition(IsUrl(val))\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      StripScript,
      IsUrl,
      IsFunc,
      IsObj,
      IsEmail,
      IsPhone,
      InArr,
      HasValue
    }} mdxType="Playground">
  {() => {
        const url = 'https://www.google.com';
        const [isUrl, setCondition] = useState(IsUrl(url));
        return <div>
          <div>是否 url: {isUrl + ''}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} defaultValue={url} onChange={e => {
            const val = e.target.value;
            setCondition(IsUrl(val));
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "isemail"
    }}>{`IsEmail`}</h2>
    <Playground __position={2} __code={'() => {\n  const email = \'test@gmail.com\'\n  const [isEmail, setCondition] = useState(IsEmail(email))\n  return (\n    <div>\n      <div>是否 email: {isEmail + \'\'}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        defaultValue={email}\n        onChange={e => {\n          const val = e.target.value\n          setCondition(IsEmail(val))\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      StripScript,
      IsUrl,
      IsFunc,
      IsObj,
      IsEmail,
      IsPhone,
      InArr,
      HasValue
    }} mdxType="Playground">
  {() => {
        const email = 'test@gmail.com';
        const [isEmail, setCondition] = useState(IsEmail(email));
        return <div>
          <div>是否 email: {isEmail + ''}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} defaultValue={email} onChange={e => {
            const val = e.target.value;
            setCondition(IsEmail(val));
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "isphone"
    }}>{`IsPhone`}</h2>
    <Playground __position={3} __code={'() => {\n  const phone = \'13344445555\'\n  const [isExpected, setCondition] = useState(IsPhone(phone))\n  return (\n    <div>\n      <div>是否 phone: {isExpected + \'\'}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        defaultValue={phone}\n        onChange={e => {\n          const val = e.target.value\n          setCondition(IsPhone(val))\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      StripScript,
      IsUrl,
      IsFunc,
      IsObj,
      IsEmail,
      IsPhone,
      InArr,
      HasValue
    }} mdxType="Playground">
  {() => {
        const phone = '13344445555';
        const [isExpected, setCondition] = useState(IsPhone(phone));
        return <div>
          <div>是否 phone: {isExpected + ''}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} defaultValue={phone} onChange={e => {
            const val = e.target.value;
            setCondition(IsPhone(val));
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "inarr"
    }}>{`InArr`}</h2>
    <p>{`元素是否在目标数组中`}</p>
    <Playground __position={4} __code={'() => {\n  const target = [\'a\', \'b\', \'c\', \'d\', \'e\', \'f\']\n  const [isExpected, setCondition] = useState(false)\n  return (\n    <div>\n      <div>\n        是否在元素 {JSON.stringify(target)} 中: {isExpected + \'\'}\n      </div>\n      <hr />\n      输入数组中的元素验证{\' \'}\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        onChange={e => {\n          const val = e.target.value\n          setCondition(InArr(target, val))\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      StripScript,
      IsUrl,
      IsFunc,
      IsObj,
      IsEmail,
      IsPhone,
      InArr,
      HasValue
    }} mdxType="Playground">
  {() => {
        const target = ['a', 'b', 'c', 'd', 'e', 'f'];
        const [isExpected, setCondition] = useState(false);
        return <div>
          <div>是否在元素 {JSON.stringify(target)} 中: {isExpected + ''}</div>
          <hr />
          输入数组中的元素验证 <input className="form-control" style={{
            width: 300
          }} onChange={e => {
            const val = e.target.value;
            setCondition(InArr(target, val));
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "hasvalue"
    }}>{`HasValue`}</h2>
    <p>{`检测目标是否为有效值（包含 0）`}</p>
    <Playground __position={5} __code={'() => {\n  const target = 0\n  const [isExpected, setCondition] = useState(false)\n  return (\n    <div>\n      <div>是否有效值: {isExpected + \'\'}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        onChange={e => {\n          const val = e.target.value\n          setCondition(HasValue(val))\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      StripScript,
      IsUrl,
      IsFunc,
      IsObj,
      IsEmail,
      IsPhone,
      InArr,
      HasValue
    }} mdxType="Playground">
  {() => {
        const target = 0;
        const [isExpected, setCondition] = useState(false);
        return <div>
          <div>是否有效值: {isExpected + ''}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} onChange={e => {
            const val = e.target.value;
            setCondition(HasValue(val));
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "isfunc"
    }}>{`IsFunc`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { IsFunc } from 'basic-helper/filter'

let func = () => {};

IsFunc(func)
`}</code></pre>
    <h2 {...{
      "id": "isobj"
    }}>{`IsObj`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { IsObj } from 'basic-helper/filter'

let func = {};

IsObj(func)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      